import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/App.css';
import AppRoutes from './Routers/AppRoutes';
import RealmProvider from './contexts/RealmProvider';
import { AppProvider } from './contexts/AppProvider'; // Adjust the path as necessary
import { AuthProvider } from './contexts/AuthProvider';
import { PackageProvider } from './contexts/PackageProvider';
import { AccessObjectProvider } from './contexts/AccessObjectProvider';
import { PlayerCampaignsProvider } from './contexts/PlayerCampaignsProvider';
import ContentViewer from './components/ContentViewer';

const App = () => {
  console.log('Reader Portal - App.js');
  
  return (
    <Router>
      <AppProvider>
        <AuthProvider>
          <PackageProvider>
            <RealmProvider>
              <AccessObjectProvider>
                <PlayerCampaignsProvider>
                  <ContentViewer />
                </PlayerCampaignsProvider>
              </AccessObjectProvider>
            </RealmProvider>
          </PackageProvider>
        </AuthProvider>
      </AppProvider>
    </Router>
  );
};

export default App;
